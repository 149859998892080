import { Injectable } from '@angular/core';
import { EAccessProfile } from 'src/app/shared/enums/access-profile.enum';
import { AuthStore } from 'src/app/shared/store/auth.store';
import { BasicUtils } from 'src/app/shared/utils/basic.utils';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(private readonly authStore: AuthStore) {}

  get isSpaceToBe(): boolean {
    return !!this.authStore?.authSnapshot?.espacoSer;
  }

  hasPermission(profiles: EAccessProfile[]): boolean {
    if (BasicUtils.isEmptyObj(this.authStore?.authSnapshot)) {
      return false;
    }

    const { perfisAcesso } = this.authStore.authSnapshot;
    return (
      perfisAcesso.length > 0 &&
      perfisAcesso.some((p) => profiles.includes(p.id))
    );
  }
}
